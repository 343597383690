.td-paging-bar {
	display: block !important;
	height: unset !important;
	margin-top: $gutter;
	& > * {
		margin: 0 !important;
		padding-left: 10px;
	}
	.td-paging-bar-navigation {
		display: inline-block;
	}

	&.custom {
		.mat-icon-button .mat-button-wrapper > * {
			vertical-align: sub;
		}
	}
}
