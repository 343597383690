.asset-request {
	&__image-preview {
		&.mat-list-base[dense] .mat-list-item {
			.mat-list-item-content {
				min-height: 40px;
			}
			.mat-divider {
				bottom: auto;
				top: 0;
			}
			&.image-preview-accordion {
				height: 0;
				overflow: hidden;
				// &.imagePreviewExpanded {
				//     height: auto;
				// }
			}
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}
