@import 'var';

*,
*::before,
*::after {
	box-sizing: border-box;
}

html,
body {
	overflow: hidden;
	min-height: 100%;
	height: 100%;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

:root {
	scroll-behavior: smooth;
	text-rendering: optimizeLegibility;
	box-sizing: border-box;
	.mat-drawer-container {
		background-color: $light;
	}
}

*:focus {
	outline: none;
}
a {
	color: inherit;
	&:hover,
	&:focus,
	&:visited {
		color: inherit;
	}
}

$list-pos-vert: 'top' 'bottom';
$list-pos-hor: 'right' 'left';

app-root {
	// fixed position
	@each $v in $list-pos-vert {
		@each $h in $list-pos-hor {
			.fixed-#{$v}-#{$h} {
				position: fixed;
				#{$v}: $gutter;
				#{$h}: $gutter;
			}
		}
	}
}

// badge
.badge {
	vertical-align: 4px;
	border-radius: 10px;
	padding: 0 8px;
	border: 1px solid #90a4ae;
	background-color: white;
	color: #546e7a;
	font-size: 12px;
	font-weight: 500;
}

$chipSide: 30px;
.chips-mini {
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	span {
		display: inline-block;
		vertical-align: middle;
		width: $chipSide;
		height: $chipSide;
		line-height: $chipSide;
		margin: 2px;
		text-align: center;
		border-radius: 50%;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
.chips-mini-container {
	text-align: right;
}
.chips-label {
	margin-right: 4px;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 14px;
	font-weight: 500;
	border: none;
	padding: 4px 12px;
	border-radius: 28px;
	text-align: center;
}

.UNSENT,
.UNAPPROVED,
.DRAFT {
	color: inherit;
	background-color: map-get($mat-secondary, 100);
}
.PARTIAL,
.BOUNCED,
.PENDING {
	background-color: $color-orange;
	color: #ffffff;
	font-weight: 500;
}

.PENDING_EUROITALIA {
	background-color: $color-blue-light;
	font-weight: 500;
}

.MORE_INFO_NEEDED {
	background-color: $color-yellow;
	color: #ffffff;
	font-weight: 500;
}

.PENDING_AGENCY {
	background-color: $color-orange-dark;
	color: #ffffff;
	font-weight: 500;
}

.APPROVED,
.COMPLETED,
.SENT_TO_CLIENT {
	background-color: $color-green;
	color: #ffffff;
	font-weight: 500;
}

.COMPLETED_AGENCY {
	background-color: $color-yellow;
	color: inherit;
	font-weight: 500;
}

.closed {
	background-color: $color-green;
	color: #ffffff;
	font-weight: 500;
}

.REJECTED {
	background-color: $color-red;
	color: #ffffff;
	font-weight: 500;
}

// mat-fab-button
.mat-fab-button {
	position: fixed !important;
	bottom: 1rem;
	right: 1rem;
}

// generators
// classes
@include backgroundColorGenerator($mat-primary, primary);

// input
input:read-only:not(:disabled) {
	opacity: 0.5;
}
.opacity-50 {
	opacity: 0.5;
}
.placeholder {
	font-style: italic;
	opacity: 0.5;
}
