@import 'var';
app-root {
  breadcrumb-project-instance {
    display: blocK;
    position: relative;
    .mat-toolbar {
      background: map-get($mat-primary, 500);
      color: $light;
      padding: 15px 3px;
      margin: 0 -#{$gutter*1.5};
      width: auto;
      .title {
          font-weight: bold;
      }
    }
  }
}