app-root {
  .mat-toolbar {
    background: transparent;
    // lo style seguente applica nel caso venga usata mat-button-toggle-group come switch in mat-toolbar
    .mat-button-toggle-group {
      font-size: 1rem;
    }

    &:first-child {
      margin-top: 0;
    }
  }
  .mat-toolbar-row,
  .mat-toolbar-multiple-rows {
    height: auto;

    h1{
      line-height: 1;
    }
  }
}

.mat-card-subtitle{
  line-height: 1;
}