@import 'var';
@import 'mixins';

@mixin inputNumberSpinHide() {
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

$tab-font-size: 14px;

app-root {
	td-data-table {
		border-bottom: none !important;

		.mat-clickable tbody > tr.td-data-table-row:hover {
			cursor: pointer;
		}

		.td-data-table-head {
			.td-data-table-heading {
				padding: 8px !important;
			}
		}

		input {
			display: block;
			width: 100%;
			// border: none;
			outline: none;
			// background-color: transparent;
			text-align: right;
			// &[type="number"] {
			//   @include inputNumberSpinHide();
			// }

			border-radius: 2px;
			border: 1px solid rgba(0, 0, 0, 0.2);
			padding: 4px;

			&:hover,
			&:active,
			&:focus {
				border: 1px solid map-get($mat-accent, 500);
			}
		}

		.td-data-table-cell {
			font-size: $tab-font-size;

			.td-data-table-cell-content-wrapper {
				padding: 8px !important;

				p {
					margin-bottom: 0 !important;
				}
				span {
					//@include text-ellipsis;
				}
			}
		}
		.td-data-table-scrollable {
			border-top: none;
		}
		.cell-subtitle {
			//@include text-ellipsis;
		}
	}
}

th.td-data-table-column {
	font-size: $tab-font-size;
}

tr.td-data-table-column-row {
	background: $light;
}

mat-icon {
	color: map-get($mat-secondary, 500) default;

	&.td-data-table-sort-icon {
		top: 10px !important;
	}
}

/* search box */
.td-search-box {
	font-size: 1rem;

	.mat-form-field-infix {
		top: 4px;
	}
}
.cell-subtitle {
	opacity: 0.5;
}
