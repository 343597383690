@import 'var';

@mixin matShadow-z($x, $y, $blur) {
	box-shadow: $x $y $blur -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

// white space and floating fixes

@mixin clearfix() {
	&::after {
		display: block;
		content: '';
		clear: both;
	}
}

// typography and adaptations

@mixin title() {
	font-size: $fontSizeTitle;
	line-height: $lineHeightTitle;
}

@mixin corps() {
	font-size: $fontSizeCorps;
	line-height: $lineHeightCorps;
}

@mixin text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
}

// positioning

@mixin centering($position, $axis) {
	position: $position;
	@if $axis == 'Y' {
		$axisVal: 'Y(-50%)';
		top: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	} @else if $axis == 'X' {
		$axisVal: 'X(-50%)';
		left: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	} @else {
		$axisVal: '(-50%,-50%)';
		top: 50%;
		left: 50%;
		-webkit-transform: translate#{$axisVal};
		-moz-transform: translate#{$axisVal};
		-o-transform: translate#{$axisVal};
		-ms-transform: translate#{$axisVal};
		transform: translate#{$axisVal};
	}
}

// layout

@mixin sibarBorderEmptySpace($pseudoElement, $fromRight: null) {
	@media (min-width: 1024px) {
		:host:not(.empty) {
			&:#{$pseudoElement} {
				content: '';
				display: block;
				@if $pseudoElement == 'before' {
					z-index: 0;
				} @else if $pseudoElement == 'after' {
					z-index: -1;
				}
				position: absolute;
				top: 0;
				@if $fromRight {
					right: $fromRight;
				} @else {
					right: $sidebarRight;
				}
				border-left: $solidGray;
				width: 0;
				height: 100%;
			}
		}
	}
}

// layout - bootstrap

@mixin col($mT: 0, $mR: 0, $mB: 0, $mL: 0, $pT: 0, $pR: 0, $pB: 0, $pL: 0) {
	.col,
	.col-1,
	.col-10,
	.col-11,
	.col-12,
	.col-2,
	.col-3,
	.col-4,
	.col-5,
	.col-6,
	.col-7,
	.col-8,
	.col-9,
	.col-auto,
	.col-lg,
	.col-lg-1,
	.col-lg-10,
	.col-lg-11,
	.col-lg-12,
	.col-lg-2,
	.col-lg-3,
	.col-lg-4,
	.col-lg-5,
	.col-lg-6,
	.col-lg-7,
	.col-lg-8,
	.col-lg-9,
	.col-lg-auto,
	.col-md,
	.col-md-1,
	.col-md-10,
	.col-md-11,
	.col-md-12,
	.col-md-2,
	.col-md-3,
	.col-md-4,
	.col-md-5,
	.col-md-6,
	.col-md-7,
	.col-md-8,
	.col-md-9,
	.col-md-auto,
	.col-sm,
	.col-sm-1,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-auto,
	.col-xl,
	.col-xl-1,
	.col-xl-10,
	.col-xl-11,
	.col-xl-12,
	.col-xl-2,
	.col-xl-3,
	.col-xl-4,
	.col-xl-5,
	.col-xl-6,
	.col-xl-7,
	.col-xl-8,
	.col-xl-9,
	.col-xl-auto {
		margin: $mT $mR $mB $mL;
		padding: $pT $pR $pB $pL;
	}
}

// generators
@mixin backgroundColorGenerator($colorMap, $name) {
	@each $key, $val in $colorMap {
		@if $key != 'contrast' {
			.background-#{$name}-#{$key} {
				background-color: map-get($colorMap, $key);
			}
		}
	}
}

/**
* questo generatore si occupa di creare delle classi che regolano il margine
* composte con 'margin' seguito la lato interessato, seguito da un numero
* multiplo di 4 (fino a 40) per la distanza in pixel, il tutto separato dal '-'
* e.g. margin-top-16
*/

$side-list: top right bottom left;

@mixin marginGenerator {
	@each $side in $side-list {
		@for $i from 1 through 10 {
			&.margin-#{$side}-#{$i*4} {
				margin-#{$side}: #{$i * 4}px;
			}
		}
	}
}

// @mixin colorGenerator() {
//   @each $key, $val in $colorMap {
//     &.color-#{$key} {
//       color: map-get($colorMap, #{$key});
//       fill:  map-get($colorMap, #{$key});
//       stroke: map-get($colorMap, #{$key});
//     }
//   }
// }

// @mixin backgroundColorGenerator() {
//   @each $key, $val in $colorMap {
//     &.background-color-#{$key} {
//         background-color: map-get($colorMap, #{$key});
//         &:hover {
//           @if $key == 'white' {
//             background-color: map-get($colorMap, 'gray');
//           }
//           @if $key == 'blue-dark' {
//             background-color: map-get($colorMap, 'blue-primary');
//           }
//         }
//     }
//   }
// }

// @mixin borderGenerator() {
//   @each $i in $list-position {
//     &.border-#{$i} {
//         border-#{$i}: $solidGray;
//     }
//   }
// }

// @mixin marginGapGenerator() {
//   @each $i in $list-position {
//     &.margin-#{$i} {
//         margin-#{$i}: $gutter;
//     }
//   }
// }
