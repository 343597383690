mat-form-field.outlined {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: 1.5px solid rgba(0, 0, 0, 0.54);
  padding: calc($gutter / 2) calc($gutter / 2) calc($gutter / 4) calc($gutter / 2);
  margin: calc($gutter / 2) 0;
}

.filter-input{
	width: 100%;
	padding: 8px 8px 0 8px;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}