.mat-raised-button {
  // &{
  @include marginGenerator();
  // }
}

.mat-raised-button {
  font-weight: 600;
  letter-spacing: 0.5px;
}
