@import "var";

app-root {
  .mat-card {
    height: 100%;
    
    mat-icon {
      color: map-get($mat-secondary, 500);
    }
    button mat-icon {
      color: inherit;
    }
    .mat-card-title {
      display: flex;
    }
    .mat-card-content {
      margin-bottom: 0;
    }
    .mat-card-footer {
      margin: 0;
      // lo style seguente applica nel caso venga usata mat-select in una mat-card-footer
      .mat-form-field {
        margin-bottom: -1.25em;
      }
    }
    // lo style seguente applica nel caso venga usata mat-toolbar come titolo di una card
    .mat-toolbar:first-child {
      //margin-top: -$gutter;
      // font-size: 1.4rem;
      .mat-toolbar-row {
        padding: 0;
      }
    }
  }
}
